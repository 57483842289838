import './style.css';
import Api  from "../../includes/api";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
   const [pictures, setPictures]                    = useState([]);
   const [current_picture, setCurrentPicture]       = useState(0);
   const [current_button, setCurrentButton]         = useState(0);
   const [pictures_refs, setPicturesRefs]           = useState([]);
   const [panel_buttons_refs, setPanelButtonsRefs]  = useState([]);
   
   const container_ref                              = useRef(0);
   const img_ref                                    = useRef(0);

   
    useEffect(() => {        
       
        loadPictures();                
        
    }, []);
    
    
    const loadPictures = async (id) => {
                       
        const pictures = await Api.get("pictures");
          
        setPictures(pictures.content);      
        
        setTimeout(() => startDiapo(pictures.content), 3000);
    };
    
    
    const startDiapo = (pictures, seek = 1) => {
                
        const last = pictures.length - 1;
        
        if(img_ref === 0){
            
            setTimeout(() => startDiapo(pictures), 1000);
        }
            
        showPicture(seek);
        
        if(seek === last){

            seek = 0
        }
        else{

            seek++;
        }
        
        console.log(seek, last);
        
        setTimeout(() => startDiapo(pictures, seek), 5000);
    };
    
    
    const savePictureRef = async (element, index) => {
       
        // Don't save the element if is array is already feeded
        if(pictures_refs.length !== pictures.length){
       
            pictures_refs.push(element); 
        }             
    };
    
    
    const saveButtonRef = async (element) => {
               
       panel_buttons_refs.push(element);        
    };
    
    
    const showPicture = (id) => {
        
        setCurrentButton(id);

        img_ref.current.style.opacity       = 0;
        container_ref.current.style.height  = img_ref.current + 23 + "px"
        
        setTimeout(() => {
            
            //setCurrentPicture(id);
          
            img_ref.current.src = pictures_refs[id].src;
           
            setTimeout(() => {
                
                container_ref.current.style.maxWidth = pictures_refs[id].naturalWidth + 22 + "px";
               
                img_ref.current.style.opacity = 1;
                
            }, 250)
            
        }, 250);            
    };
   
    
    
    return (                 
        <div id="pictures">   

            <div id="container" ref={container_ref}>
                { 
                    pictures.length > 0 ?  <img ref={img_ref} src={"/uploaded/"+pictures[current_picture].picture} /> : <></>                   
                }                
            </div>
            <div id="panel">
            {
                pictures.map((picture, index) => {

                    return  <div 
                                class   = {index == current_button ? "button selected" : "button"}
                                ref     = {el => saveButtonRef(el)}
                                onClick = {(e) => showPicture(index)}>
                            </div>
                })
            }
            </div>
            <div id="preload">
            {
                pictures.map((picture, index) => {

                    return <img ref={el => savePictureRef(el, index)} src={"/uploaded/"+picture.picture} />
                })
            }
            </div>
        </div>                       
    );
} 