import './style.css';
import Api      from "../../includes/api";

import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
    const [prices,    setPrices]  = useState({});
 
    useEffect(() => {
                
        loadPrices();
        
    }, []);
    
    
    const loadPrices = async () => {

        const prices = await Api.get("rental");  

        setPrices(prices.content);
    };


    const getPrice = (category, days) => {
        
            const price = prices?.[category]?.[days] || "";
        
            return price !== "" ? <>{price}<span class="small-euro-symbol">€</span></> : "";
    };
    
    
    const drawRentalMobileTable = (caption, category) => {
    
        const days  = ["1/2", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13"];
        const tr    = []
        
        for(const day of days){
            
            tr.push(
                <tr>
                    <td>{day}</td>
                    <td>{getPrice(category,day)}</td>
                </tr>
            );
        }
        
        return  <table> 
                    <caption>{caption}</caption>
                    <tr>
                        <th>Jours</th>
                        <th>Tarif</th>
                    </tr>
                    {tr}
                </table>
    };
    
    return ( 
        <>
        <h1 class="h1-with-icon">
            <div><img src="pics/rental-icon.png" /></div>
            <div>Tarifs de location</div>              
        </h1>
        <div id="rental">
            
            <div id="rental-mobile">
            
                <div class="category">Snowboard/Ski</div>
                
                <div class="prices-grid">
                    {drawRentalMobileTable("Premium","premium")}
                    {drawRentalMobileTable("Performance","performance")}
                    {drawRentalMobileTable("Evolution","evolution")}
                    {drawRentalMobileTable("Champion","champion")}
                    {drawRentalMobileTable("Kids","kids")}                    
                </div>
                
                <br/><br/><br/><br/>
                <div class="category">Boots</div>
                
                <div class="prices-grid">
                    {drawRentalMobileTable("Premium","boots_premium")}                   
                    {drawRentalMobileTable("Evolution","boots_evolution")}                  
                    {drawRentalMobileTable("Kids","boots_kids")}                    
                </div>
                
                <br/><br/><br/><br/>
                <div class="category">StepOn</div>
                
                <div class="prices-grid">
                    {drawRentalMobileTable("StepOn","stepon")}                                                     
                </div>
                
                <br/><br/><br/><br/>
             
                <div class="category">Casques/bâtons</div>
                
                <div class="prices-grid">
                    {drawRentalMobileTable("Casques/bâtons","helmet_poles")}                                                     
                </div>
            </div>
            
            <div id="rental-large">
                              
                <table>
               
                <tr>
                    <th id="days-label">Jours</th>
                    <th>1/2</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                </tr>
                 <tr>
                    <td colspan="15" class="category">Snowboard/Ski</td>
                </tr>
                <tr>
                    <td class="title">Premium</td>
                    <td>{getPrice("premium", "1/2")}</td>
                    <td>{getPrice("premium", "1")}</td>
                    <td>{getPrice("premium", "2")}</td>
                    <td>{getPrice("premium", "3")}</td>
                    <td>{getPrice("premium", "4")}</td>
                    <td>{getPrice("premium", "5")}</td>
                    <td>{getPrice("premium", "6")}</td>
                    <td>{getPrice("premium", "7")}</td>
                    <td>{getPrice("premium", "8")}</td>
                    <td>{getPrice("premium", "9")}</td>
                    <td>{getPrice("premium", "10")}</td>
                    <td>{getPrice("premium", "11")}</td>
                    <td>{getPrice("premium", "12")}</td>
                    <td>{getPrice("premium", "13")}</td>
                </tr>
                <tr>
                    <td class="title">Performance</td>
                    <td>{getPrice("performance", "1/2")}</td>
                    <td>{getPrice("performance", "1")}</td>
                    <td>{getPrice("performance", "2")}</td>
                    <td>{getPrice("performance", "3")}</td>
                    <td>{getPrice("performance", "4")}</td>
                    <td>{getPrice("performance", "5")}</td>
                    <td>{getPrice("performance", "6")}</td>
                    <td>{getPrice("performance", "7")}</td>
                    <td>{getPrice("performance", "8")}</td>
                    <td>{getPrice("performance", "9")}</td>
                    <td>{getPrice("performance", "10")}</td>
                    <td>{getPrice("performance", "11")}</td>
                    <td>{getPrice("performance", "12")}</td>
                    <td>{getPrice("performance", "13")}</td>
                </tr>
                <tr>
                    <td class="title">Evolution</td>
                    <td>{getPrice("evolution", "1/2")}</td>
                    <td>{getPrice("evolution", "1")}</td>
                    <td>{getPrice("evolution", "2")}</td>
                    <td>{getPrice("evolution", "3")}</td>
                    <td>{getPrice("evolution", "4")}</td>
                    <td>{getPrice("evolution", "5")}</td>
                    <td>{getPrice("evolution", "6")}</td>
                    <td>{getPrice("evolution", "7")}</td>
                    <td>{getPrice("evolution", "8")}</td>
                    <td>{getPrice("evolution", "9")}</td>
                    <td>{getPrice("evolution", "10")}</td>
                    <td>{getPrice("evolution", "11")}</td>
                    <td>{getPrice("evolution", "12")}</td>
                    <td>{getPrice("evolution", "13")}</td>
                </tr>
                <tr>
                    <td class="title">Champion</td>
                    <td>{getPrice("champion", "1/2")}</td>
                    <td>{getPrice("champion", "1")}</td>
                    <td>{getPrice("champion", "2")}</td>
                    <td>{getPrice("champion", "3")}</td>
                    <td>{getPrice("champion", "4")}</td>
                    <td>{getPrice("champion", "5")}</td>
                    <td>{getPrice("champion", "6")}</td>
                    <td>{getPrice("champion", "7")}</td>
                    <td>{getPrice("champion", "8")}</td>
                    <td>{getPrice("champion", "9")}</td>
                    <td>{getPrice("champion", "10")}</td>
                    <td>{getPrice("champion", "11")}</td>
                    <td>{getPrice("champion", "12")}</td>
                    <td>{getPrice("champion", "13")}</td>
                </tr>
                <tr>
                    <td class="title">Kids</td>
                    <td>{getPrice("kids", "1/2")}</td>
                    <td>{getPrice("kids", "1")}</td>
                    <td>{getPrice("kids", "2")}</td>
                    <td>{getPrice("kids", "3")}</td>
                    <td>{getPrice("kids", "4")}</td>
                    <td>{getPrice("kids", "5")}</td>
                    <td>{getPrice("kids", "6")}</td>
                    <td>{getPrice("kids", "7")}</td>
                    <td>{getPrice("kids", "8")}</td>
                    <td>{getPrice("kids", "9")}</td>
                    <td>{getPrice("kids", "10")}</td>
                    <td>{getPrice("kids", "11")}</td>
                    <td>{getPrice("kids", "12")}</td>
                    <td>{getPrice("kids", "13")}</td>
                </tr>
                 <tr>
                    <td colspan="15" class="category">Boots</td>
                </tr>
                <tr>
                    <td class="title">Premium</td>
                    <td>{getPrice("boots_premium", "1/2")}</td>
                    <td>{getPrice("boots_premium", "1")}</td>
                    <td>{getPrice("boots_premium", "2")}</td>
                    <td>{getPrice("boots_premium", "3")}</td>
                    <td>{getPrice("boots_premium", "4")}</td>
                    <td>{getPrice("boots_premium", "5")}</td>
                    <td>{getPrice("boots_premium", "6")}</td>
                    <td>{getPrice("boots_premium", "7")}</td>
                    <td>{getPrice("boots_premium", "8")}</td>
                    <td>{getPrice("boots_premium", "9")}</td>
                    <td>{getPrice("boots_premium", "10")}</td>
                    <td>{getPrice("boots_premium", "11")}</td>
                    <td>{getPrice("boots_premium", "12")}</td>
                    <td>{getPrice("boots_premium", "13")}</td>
                </tr>
                <tr>
                    <td class="title">Evolution</td>
                    <td>{getPrice("boots_evolution", "1/2")}</td>
                    <td>{getPrice("boots_evolution", "1")}</td>
                    <td>{getPrice("boots_evolution", "2")}</td>
                    <td>{getPrice("boots_evolution", "3")}</td>
                    <td>{getPrice("boots_evolution", "4")}</td>
                    <td>{getPrice("boots_evolution", "5")}</td>
                    <td>{getPrice("boots_evolution", "6")}</td>
                    <td>{getPrice("boots_evolution", "7")}</td>
                    <td>{getPrice("boots_evolution", "8")}</td>
                    <td>{getPrice("boots_evolution", "9")}</td>
                    <td>{getPrice("boots_evolution", "10")}</td>
                    <td>{getPrice("boots_evolution", "11")}</td>
                    <td>{getPrice("boots_evolution", "12")}</td>
                    <td>{getPrice("boots_evolution", "13")}</td>
                </tr>
                <tr>
                    <td class="title">Kids</td>
                    <td>{getPrice("boots_kids", "1/2")}</td>
                    <td>{getPrice("boots_kids", "1")}</td>
                    <td>{getPrice("boots_kids", "2")}</td>
                    <td>{getPrice("boots_kids", "3")}</td>
                    <td>{getPrice("boots_kids", "4")}</td>
                    <td>{getPrice("boots_kids", "5")}</td>
                    <td>{getPrice("boots_kids", "6")}</td>
                    <td>{getPrice("boots_kids", "7")}</td>
                    <td>{getPrice("boots_kids", "8")}</td>
                    <td>{getPrice("boots_kids", "9")}</td>
                    <td>{getPrice("boots_kids", "10")}</td>
                    <td>{getPrice("boots_kids", "11")}</td>
                    <td>{getPrice("boots_kids", "12")}</td>
                    <td>{getPrice("boots_kids", "13")}</td>
                </tr>
                <tr>
                    <td colspan="15" class="category"></td>
                </tr>
                <tr>
                    <td class="title"><b>StepOn</b></td>
                    <td>{getPrice("stepon", "1/2")}</td>
                    <td>{getPrice("stepon", "1")}</td>
                    <td>{getPrice("stepon", "2")}</td>
                    <td>{getPrice("stepon", "3")}</td>
                    <td>{getPrice("stepon", "4")}</td>
                    <td>{getPrice("stepon", "5")}</td>
                    <td>{getPrice("stepon", "6")}</td>
                    <td>{getPrice("stepon", "7")}</td>
                    <td>{getPrice("stepon", "8")}</td>
                    <td>{getPrice("stepon", "9")}</td>
                    <td>{getPrice("stepon", "10")}</td>
                    <td>{getPrice("stepon", "11")}</td>
                    <td>{getPrice("stepon", "12")}</td>
                    <td>{getPrice("stepon", "13")}</td>
                </tr>
                <tr>
                    <td colspan="15" class="category"></td>
                </tr>
                <tr>
                    <td class="title"><b>Casque/Bâtons</b></td>
                    <td>{getPrice("helmet_poles", "1/2")}</td>
                    <td>{getPrice("helmet_poles", "1")}</td>
                    <td>{getPrice("helmet_poles", "2")}</td>
                    <td>{getPrice("helmet_poles", "3")}</td>
                    <td>{getPrice("helmet_poles", "4")}</td>
                    <td>{getPrice("helmet_poles", "5")}</td>
                    <td>{getPrice("helmet_poles", "6")}</td>
                    <td>{getPrice("helmet_poles", "7")}</td>
                    <td>{getPrice("helmet_poles", "8")}</td>
                    <td>{getPrice("helmet_poles", "9")}</td>
                    <td>{getPrice("helmet_poles", "10")}</td>
                    <td>{getPrice("helmet_poles", "11")}</td>
                    <td>{getPrice("helmet_poles", "12")}</td>
                    <td>{getPrice("helmet_poles", "13")}</td>
                </tr>
                </table>
            </div>
        </div>
        </>
    );
} 