import './style.css';
import Api  from "../../includes/api";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
   const [boards, setBoards] = useState([
       
       "burton",
       "rome",
       "jones",
       "capita",
       "amplid",
       "bataleon",
       "libtech",
       "dc",
       "vans",
       "union",
       "nidecker"
    ]);

    const [wear, setWear] = useState([
       
       "volcom",
       "quiksilver",
       "roxy",
       "686",
       "element",
       "rvca",
       "eivy",
       "stance",
       "dakine",
       "nixon",
       "dainese"
    ]);
    
    const [accessories, setAccessories] = useState([
        
        "anon",
        "smith",
        "oakley",
        "vonzipper",
        "dragon",
        "crab-grab"
    ]);
    
    return (                
        <div id="brands">

            <h1 class="h1-with-icon">
                <div><img src="pics/flake-icon.png" /></div>
                <div>Boards, boots & bindings</div>              
            </h1>
            <div class="logos-container">
                <div class="logos">   
                {
                    boards.map(picture => { 

                    return  <div class="logo">
                            <img src={"pics/brands/" + picture +".png"} alt={picture} title={picture} id={picture}/>
                            </div>
                    })
                }            
                </div> 
            </div>
            
            
            <h1 class="h1-with-icon">
                <div><img src="pics/wear-icon.png" /></div>
                <div>Wear</div>              
            </h1>
            <div class="logos-container">
                <div class="logos">   
                {
                    wear.map(picture => { 

                    return <div class="logo" style={{backgroundImage: "url(public/pics/brands/" + {picture} + ".png)"}}>
                    <img src={"pics/brands/" + picture +".png"} alt={picture} title={picture} id={picture}/>
                    </div>
                    })
                }            
                </div>
            </div>
            
            <h1 class="h1-with-icon">
                <div><img src="pics/goggle-icon.png" /></div>
                <div>Accessories</div>              
            </h1>
            <div class="logos-container">
                <div class="logos">   
                {
                    accessories.map(picture => { 

                    return <div class="logo" style={{backgroundImage: "url(public/pics/brands/" + {picture} + ".png)"}}>
                    <img src={"pics/brands/" + picture +".png"} alt={picture} title={picture} id={picture}/>
                    </div>
                    })
                }            
                </div>
            </div>
        </div>
    );
} 