import './style.css';


export default (props) => {

    return ( 
        <>
        <div id="menu"><a href="/admin">Gérer les images</a> - <a href="/admin/rental">Grille des prix</a></div>
        </>
    );
} 