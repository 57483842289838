import './style.css';
import Api  from "../../includes/api";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
   //<div id="map-link"><img src="pics/view-on-google-maps.png" /></div>
    return (    
       
            <div id="google-maps">        
                <h1 class="h1-with-icon">
                    <div><img src="pics/google-maps.png" /></div>
                    
                    <a href="https://www.google.com/maps/place/BlackCats+snowboard+shop+tignes/@45.4563658,6.9010255,396m/data=!3m1!1e3!4m6!3m5!1s0x478974b22ac4328d:0xcca132b1cb085e99!8m2!3d45.4563451!4d6.9010141!16s%2Fg%2F1tff1y0m?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank">
                    Open Blackcats shop on Google map</a>

                </h1>
                <div id="address">
                                        
                    <div id="postal" >
                        55, Place du curling
                        <br />
                        73320 Tignes Val Claret
                        <br />
                        France
                    </div>

                    <div id="contact" class="black-container">
                        <div class="entry">
                            <img src="pics/phone-icon.png" />
                            <div>+33(0)4 79 06 42 46</div>
                        </div>
                        <div class="entry">
                            <img src="pics/mail-icon.png" />
                            <div>blackcatstignes@gmail.com</div>
                        </div>            
                    </div>

                    <div id="open-hours" class="black-container">      
                        <div class="entry">
                            Lundi, Mardi, Mercredi, Jeudi, Vendredi
                            <br />                    
                            8h30-12h30  14h30-19h30
                        </div>
                        <div class="entry">                 
                            Samedi, Dimanche
                            <br/>
                            8h30-19h30
                        </div>
                    </div>
                </div>

            </div>

    );
} 