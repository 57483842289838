import './style.css';
import Api  from "../../includes/api";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
   
    return (                
        <div id="footer">
        <div id="bottom-header"></div>
        <div id="wood-planks"></div>
        <div id="black-snow"></div>
        </div>
    );
} 