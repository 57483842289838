import './style.css';
import Api      from "../../includes/api";
import Loader   from "../../elements/loader/loader";
import Menu     from "./menu";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
    const [pictures,    setPictures]    = useState([]);
    const [is_loading, setIsLoading]    = useState(false);
    const input_file_ref                = useRef(0);  
 
    useEffect(() => {
        
        loadPictures();
        
    }, []);
    
    const loadPictures = async () => {
       
        setIsLoading(true);
        
        const pictures = await Api.get("pictures");  
        
        setPictures(pictures.content);
        
        setIsLoading(false);
    };
    
    
    const onOpenFilesBrowser = () => {
        
        if(pictures.length < 10){
            
            input_file_ref.current.click();
        }
    };
    
    
    const onFileChange = async (e) => {

        const formData = new FormData();
        
        formData.append("file", e.target.files[0]);
        
        setIsLoading(true);
        
        await Api.post("pictures/save", formData);
      
        loadPictures();
    };
    
    
    const onDeletePicture = async(id) => {
        
        const pictures = await Api.post("pictures/delete", {id:id});  
        
        loadPictures();
    };
    
    
    return ( 
        <section>
        <Menu />
        <div id="admin-pictures">         
            {is_loading ? <Loader /> : <button class="blue-button" onClick={onOpenFilesBrowser}>Envoyer une nouvelle image</button>}
            {pictures.length === 10 ? <span id="reached-limit">Le nombre maximal d'images est atteint.</span> : <></>}
            <input type="file" ref={input_file_ref} onChange={onFileChange} />
                    
                <div class="pictures">
                {
                    pictures.map(picture => {
                        
                    return  <div class="picture">
                                <img src={"./uploaded/" + picture.picture} height="150" />
                                <div><button class="blue-button" onClick={(e) => onDeletePicture(picture.id)}>Supprimer</button></div>
                            </div>
                    })
                }
                </div>
        </div>
        </section>
    );
} 